import React, { Component } from 'react'
import { Container } from '@material-ui/core'
import Copy from '../../common/Copy'
import Config from '../../common/Config'
import Header from '../../components/header/Header'
import Camera from '../../components/camera/Camera'
import CameraAccess from '../../components/camera/CameraAccess'
import Loading from '../../components/loading/Loading'
import Toast from '../../components/toast/Toast'

class Main extends Component {
  state = {
    isLoaded: false,
    isCameraEnabled: false,
    bottles: [],
    isToastOpen: false,
    toastVariant: 'begin',
    sku: null,
    uid: null,
    crown: null,
    bottle: null
  }

  componentDidMount() {
    document.body.classList.add('grey-bg', 'no-scroll') // TODO: Use state instead of this lame hack
    this.checkCameraPermission()
    this.fetchBottles()
  }

  checkCameraPermission = () => {
    // Does the app have permission to use camera?
    if (navigator && navigator.permissions) {
      return navigator.permissions.query({ name: 'camera' }).then(result => {
        if (result.state === 'granted') {
          this.setState({
            isCameraEnabled: true,
            isToastOpen: true
          })
        }
      })
    } else {
      this.setState({
        isCameraEnabled: true,
        isToastOpen: true
      })
    }
  }

  fetchBottles = () => {
    fetch(`${Config.apiEndpoint}/bottles`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(
        data => {
          this.setState({
            bottles: data.items,
            isLoaded: true
          })
        },
        error => {
          this.setState({
            isLoaded: true,
            isError: true
          })
        }
      )
  }

  getBranding = bottle => {
    return {
      logo: (bottle && bottle.logo) || '',
      colourBg: (bottle && bottle.colourBg) || '',
      colourFg: (bottle && bottle.colourFg) || '',
      title: (bottle && bottle.title) || '',
      outline: (bottle && bottle.outline) || '',
      toast: (bottle && bottle.toast) || '',
      automlModel: (bottle && bottle.automlModel) || ''
    }
  }

  handleRequestCamera = () => {
    if (navigator) {
      return navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(p => {
          this.setState({
            isCameraEnabled: true,
            isToastOpen: true
          })
        })
        .catch(err => {
          // Camera denied
        })
    }
  }

  handleToastClose = () => {
    this.setState({
      toastVariant: null
    })
    setTimeout(() => {
      this.setState({ isToastOpen: false })
    }, 300)
  }

  handleChangeToastVariant = toastVariant => {
    this.setState({
      toastVariant
    })
    setTimeout(() => {
      this.setState({ isToastOpen: true })
    }, 300)
  }

  handleUid = uid => {
    this.setState({ uid })
  }

  handleBottle = bottle => {
    this.setState({ bottle })
  }

  handleCrown = crown => {
    this.setState({ crown })
  }

  handleRedeemPoints = () => {
    this.props.handleAddPoints(10)
    this.handleMarkCrownAsRedeemed()
  }

  handleMarkCrownAsRedeemed = () => {
    let { crown } = { ...this.state }
    crown.redeemable = false
    crown.closed = false
    fetch(`${Config.apiEndpoint}/crowns/redeem/${crown._id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(crown)
    })
      .then(res => res.json())
      .then(
        data => {
          this.handleChangeToastVariant('redeemed')
        },
        error => {
          // TODO: error handling
          this.handleChangeToastVariant('redeemed')
        }
      )
  }

  render() {
    const {
      isLoaded,
      isCameraEnabled,
      bottles,
      isToastOpen,
      toastVariant,
      uid,
      sku,
      bottle
    } = this.state
    const { points } = this.props

    return (
      <>
        {!process.env.REACT_APP_DISABLE_HEADER && (
          <Header branding={this.getBranding(bottle)} points={points} />
        )}
        <Container>
          {!isLoaded && <Loading />}
          {isLoaded &&
            (isCameraEnabled ? (
              <Camera
                handleCrown={this.handleCrown.bind(this)}
                handleUid={this.handleUid.bind(this)}
                handleBottle={this.handleBottle.bind(this)}
                handleChangeToastVariant={this.handleChangeToastVariant.bind(
                  this
                )}
                bottles={bottles}
                handleToastClose={this.handleToastClose.bind(this)}
              />
            ) : (
              <CameraAccess
                handleRequestCamera={this.handleRequestCamera.bind(this)}
                copy={Copy.userScan}
              />
            ))}
        </Container>
        {isToastOpen && toastVariant && (
          <Toast
            copy={Copy.userScan}
            uid={uid}
            sku={sku}
            isCameraEnabled={isCameraEnabled}
            isToastOpen={isToastOpen}
            toastVariant={toastVariant}
            handleToastRedeem={this.handleRedeemPoints.bind(this)}
            handleToastClose={this.handleToastClose.bind(this)}
          />
        )}
      </>
    )
  }
}

export default Main
