import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import Config from '../../common/Config'
import Logo from '../../components/logo/Logo'

class Home extends Component {
  state = {
    isLoaded: false,
    isEmpty: false,
    isError: false,
    crown: {}
  }

  componentDidMount() {
    this.handleLoadPage()
  }

  handleLoadPage = () => {
    // Get URL query params
    const urlParams = new URLSearchParams(window.location.search)
    const uid = urlParams.has('uid') ? urlParams.get('uid') : null

    if (uid !== null) {
      this.handleFetchCrown(uid)
    } else {
      this.setState({
        isLoaded: true,
        isEmpty: true,
        isError: false
      })
    }
  }

  handleFetchCrown = uid => {
    fetch(`${Config.apiEndpoint}/crowns/uid/${uid}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(
        data => {
          if (data.message === 'Crown found') {
            this.setState({
              crown: data.crown,
              isLoaded: true,
              isError: false
            })
            this.handleRedirectPage()
          } else {
            this.setState({
              isLoaded: true,
              isError: true
            })
          }
        },
        error => {
          this.setState({
            isLoaded: true,
            isError: true
          })
        }
      )
  }

  handleRedirectPage = status => {
    let redirectUri
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
    const useIOSRedirect =
      isIOS &&
      process.env.REACT_APP_ENABLE_IOS_REDIRECT &&
      process.env.REACT_APP_IOS_REDIRECT_URL !== ''

    // If running on iOS, use iOS redirect URL,
    // if not, check if crown redirect is available,
    // otherwise default to bottle redirect
    redirectUri = useIOSRedirect
      ? process.env.REACT_APP_IOS_REDIRECT_URL
      : this.state.crown.redirectUri
      ? this.state.crown.redirectUri
      : this.state.crown.bottle[0].redirectUri

    window.location = `${redirectUri}${status ? '?redeemable=' + status : ''}`
  }

  render() {
    return (
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justify="center"
        className="Container fullscreen white-bg">
        <Logo />
        <p>Testing update 5</p>
      </Grid>
    )
  }
}

export default Home
